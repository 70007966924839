
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import dayjs from "dayjs";
import ReturnCard from "./components/return-card.vue";
import t from "@common/src/i18n/t";
const service = namespace("service");
const base = namespace("base");
const purchase = namespace("purchase");
const marketing = namespace("marketing");
const customer = namespace("customer");
@Component({
  components: { ReturnCard }
})
@Table("loadListData")
export default class ServiceList extends Vue {
  dayjs = dayjs;
  @base.Action getDictionaryList;
  @marketing.Action queryCardList;
  @marketing.Action queryCardTemplateList;
  @marketing.Action cardActivation;
  @purchase.Action purchaseComplete;
  @service.Action createInvoice;
  @purchase.Action createPurchasePayment;
  @purchase.Mutation setPaymentBtnLoading;
  @purchase.Mutation setMakeInvoiceBtnLoading;
  @customer.Action getCustomerList;
  @customer.Action getCustomerDetail;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  orderList: any[] = [];
  activeRow: any = {};
  customerList: any[] = [];
  cardCode = null;
  searchForm = {
    mobile: "",
    dataTime: "",
    openTimeBegin: "",
    openTimeEnd: "",
    cardType: "",
    cardName: "",
    cardStatus: "",
    issueType: null,
    remark: "",
  };
  showComfirmPayment: boolean = false;
  showRegisterInvoice: boolean = false;
  seletedCustomer: string = "";
  orderStatusList: any[] = [];
  staffList: any[] = [];
  orderFormData: any = {
    mobile: "",
    firstName: ""
  };
  handleInvoiceConfirmClick(data) {
    this.setMakeInvoiceBtnLoading(true);
    this.createInvoice(data)
      .then(data => {
        this.$message.success(this.$t("purchase.invoice-register-success") as string);
        this.showRegisterInvoice = false;
        this.loadListData();
      })
      .finally(() => {
        this.setMakeInvoiceBtnLoading(false);
      });
  }
  created() {
    this.init();
    this.loadListData();
  }
  cardTemplateTypeList: any = [];
  cardNameList: any = [];
  init() {
    this.getDictionaryList("card_type").then(data => {
      this.cardTemplateTypeList = data;
    });
    this.queryCardTemplateList({
      pageNum: 1,
      pageSize: 999,
      status: 1,
      cardType: ""
    }).then(data => {
      data.data.list.forEach(l => {
        l.label = l.cardTemplateName;
        l.value = l.cardTemplateName;
      });
      this.cardNameList = data.data.list;
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    // const { mobile, firstName } = this.orderFormData;
    // Object.assign(this.searchForm, {
    //   firstName,
    //   mobile
    // });
    return this.queryCardList({
      ...this.searchForm,
      orderItems: this.mix_sortParams,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      data.data.list.forEach(l => {
        const _plateCodes = [];
        if (l.customerVehicleList) {
          l.customerVehicleList.forEach(c => {
            _plateCodes.push(c.plateCode);
          });
        }
        l.plateCodes = _plateCodes.join(" | ");
      });
      this.orderList = data.data.list;
      return data;
    });
  }
  handleSwitchStatus(tab) {
    this.mix_pageNum = 1;
    this.loadListData();
  }
  get searchList() {
    return [
      {
        label: "卡号",
        type: "input",
        value: "",
        prop: "cardCode"
      },
      {
        label: "手机号",
        type: "input",
        value: "",
        prop: "mobile"
      },

      {
        label: "卡模版编号",
        type: "input",
        value: "",
        prop: "cardTemplateCode"
      },
      {
        label: this.$t("v210906.card-opening-time"),
        type: "daterange",
        value: "",
        prop: "dataTime"
      },
      {
        label: "marketing.card-type",
        type: "select",
        value: "",
        selectList: this.cardTemplateTypeList,
        prop: "cardType"
      },
      {
        label: "卡名称",
        type: "select",
        value: "",
        selectList: this.cardNameList,
        prop: "cardName"
      },
      {
        label: "卡状态",
        type: "select",
        value: "",
        selectList: [
          {
            label: "全部",
            value: ""
          },
          {
            label: "使用中",
            value: "1"
          },
          {
            label: "已失效",
            value: "2"
          }
        ],
        prop: "cardStatus"
      },
      {
        label: "备注",
        type: "input",
        value: "",
        prop: "remark"
      },
      {
        label: "发放类型",
        type: "select",
        value: "",
        selectList: [
          {
            label: "全部",
            value: null
          },
          {
            label: "购买",
            value: 1
          },
          {
            label: "兑换",
            value: 2
          },
          {
            label: "赠送",
            value: 3
          },
          {
            label: "活动领取",
            value: 4
          },
          {
            label: "卡升级",
            value: 5
          },
          {
            label: "卡合成",
            value: 6
          }
        ],
        prop: "issueType"
      }
    ];
  }
  handleSearch(data, type) {
    this.mix_pageNum = 1;
    if (type) {
      this.seletedCustomer = "";
      // this.orderFormData = {
      //   mobile: "",
      //   firstName: ""
      // };
    }
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "dataTime") {
          this.searchForm["openTimeBegin"] = item.value ? item.value[0] : "";
          this.searchForm["openTimeEnd"] = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  handleLinkOrderView(row) {
    let { href } = this.$router.resolve({
      path: `/service/view/${row.orderNo}`
    });
    window.open(href, "_blank");
  }
  handleReturn(row) {
    if(row.activityCode != '0') {
      this.$message.warning("此卡为营销活动售卖卡券");
    }
    this.activeRow = row;
    this.cardCode = row.cardCode;
    this.$nextTick(() => {
      const refs = this.$refs.returnCard as any;
      console.log("[ refs ]", refs);
      refs.showRefundModalFn();
    });
  }

  handleView(row) {
    this.$router.push(`/marketing/card-view/${row.cardCode}`);
  }

  handleActivation(row) {
    const { cardCode } = row;
    this.$confirm("确定要激活吗", t("v210831.tips"), {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
      .then(() => {
        this.cardActivation({ cardCode }).then(res => {
          this.$message.success("激活成功");
          this.loadListData();
        });
      })
      .catch(() => {});
  }
  returnFn() {
    this.loadListData();
  }
  handleSearchCustomer(val) {
    if (val) {
      this.getCustomerList({
        pageSize: 20,
        pageNum: 1,
        searchKey: val
      }).then(data => {
        if (!data.data.list) {
          this.customerList = [];
        } else {
          this.customerList = data.data.list.map(item => {
            item.label = `${item.storeName } ${ "/" + item.plateCode}${item.customerName ? "/" + item.customerName : ""} ${
              item.mobile ? "/" + item.mobile : ""
            }`;
            return item;
          });
        }
      });
    } else {
      this.customerList = [];
    }
  }
  handleChooseCustomer(val) {
    const customer = this.customerList[val];
    this.orderFormData = {
      mobile: "",
      firstName: ""
    };
    if (customer) {
      this.setCustomerData(customer.vehicleCode, customer.customerCode);
    }
  }
  setCustomerData(vehicleCode, customerCode) {
    this.orderFormData.customerCode = customerCode;
    this.getCustomerDetail({
      customerCode: customerCode,
      vehicleCode: vehicleCode
    }).then(data => {
      const customerData = data.data;
      if (!customerData.customerName || !customerData.mobile) {
        this.$confirm(t("v210906.you-need-the-customers-name-and-mobile-phone"), t("v210831.tips"), {
          confirmButtonText: t("v210831.determine"),
          cancelButtonText: t("base.cancel"),
          type: "warning"
        })
          .then(() => {
            let { href } = this.$router.resolve({
              path: `/setting/customer-edit/${customerCode}?vehicleCode=${customerData.vehicleList[0].vehicleCode}`
            });
            window.open(href, "_blank");
          })
          .catch(() => {});
      } else {
        // this.orderFormData.firstName = customerData.customerName;
        this.orderFormData.mobile = customerData.mobile;
      }
    });
  }
  handleUserView(row) {
    let { href } = this.$router.resolve({
      path: `/customer/customer-view/${row.mobile}`
    });
    window.open(href, "_blank");
  }
}
